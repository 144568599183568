import { FirebaseApp, initializeApp } from 'firebase/app';
import * as m from '../../../../src/paraglide/messages';
import type { ErrorMessages } from '../interfaces/general';

const firebaseConfig = {
	apiKey: process.env.FIREBASE_API_KEY,
	authDomain: process.env.FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.FIREBASE_DATABASE_URL,
	projectId: process.env.FIREBASE_PROJECT_ID,
	storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.FIREBASE_APP_ID,
	measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const app: FirebaseApp = initializeApp(firebaseConfig);

const firebaseErrorMessages: ErrorMessages = {
	'auth/invalid-credential': m.auth_invalid_credentials(),
	'auth/invalid-email': m.auth_invalid_email(),
	'auth/user-disabled': m.auth_user_disabled(),
	'auth/too-many-requests': m.auth_too_many_requests(),
	'auth/user-not-found': m.user_not_found(),
	'auth/email-already-in-use': m.auth_email_already_in_use(),
};

export function getFirebaseErrorMessage(code: string): string {
	return firebaseErrorMessages[code] || m.unknown_error();
}
