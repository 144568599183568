
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_credentials = () => `L'email o la password che hai inserito non sono corrette`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_email = () => `L'Email che hai inserito non è valida`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_user_disabled = () => `Questo account è stato disabilitato`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_too_many_requests = () => `L'accesso a questo account è stato temporaneamente disabilitato a causa di molti tentativi di accesso falliti. Puoi ripristinarlo immediatamente reimpostando la tua password o puoi riprovare più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unknown_error = () => `Si è verificato un errore sconosciuto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_error_heading = () => `Sembra che ci sia stato un errore.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_not_found = () => `Utente non trovato`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_email_already_in_use = () => `Questo indirizzo email è già associato a un account. Ti sei già registrato? Prova ad accedere o utilizza un altro indirizzo email.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_email = () => `L'indirizzo email non è valido`




	
/**
 * @param {{ label: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_string = (params) => `Il campo ${params.label} non è valido`




	
/**
 * @param {{ label: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const too_big = (params) => `Il campo ${params.label} è troppo corto`




	
/**
 * @param {{ label: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const too_small = (params) => `Il campo ${params.label} è troppo corto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invalid_privacy = () => `Il campo Privacy è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const email_label = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const password_label = () => `Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const name_label = () => `Nome`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const surname_label = () => `Cognome`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_label = () => `Privacy`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_error_unknown = () => `Oops! Si è verificato un errore durante il login. Per favore, riprova più tardi.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_name = () => `Il campo 'Nome' non può essere vuoto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_surname = () => `Il campo 'Cognome' non può essere vuoto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const auth_invalid_password = () => `La password è troppo corta (minimo 6 caratteri)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tax_id_required = () => `Il campo P.IVA / Codice Fiscale è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tax_id_too_long = () => `Il campo P.IVA / Codice Fiscale deve essere lungo al massimo 20 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const phone_required = () => `Il campo Telefono è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_required = () => `Il campo Indirizzo è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const state_required = () => `Il campo Provincia è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const city_required = () => `Il campo Città è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zip_required = () => `Il campo CAP è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const name_too_long = () => `Il campo nome deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const surname_too_long = () => `Il campo cognome deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const phone_too_long = () => `Il campo telefono deve contenere al massimo 25 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const email_too_long = () => `Il campo email deve contenere al massimo 50 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_too_long = () => `Il campo indirizzo deve contenere al massimo 255 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const state_too_long = () => `Il campo stato deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const city_too_long = () => `Il campo città deve contenere al massimo 100 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zip_too_long = () => `Il campo CAP deve contenere al massimo 20 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const company_too_long = () => `Il campo Azienda è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_size_required = () => `Il campo Tipo del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_size_too_long = () => `Il campo Tipo del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_name_required = () => `Il campo Nome del negozio deve avere tra 2 e 30 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_name_too_long = () => `Il campo Nome del negozio deve avere tra 2 e 30 caratteri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_address_required = () => `Il campo Indirizzo del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_address_too_long = () => `Il campo Indirizzo del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_state_required = () => `Il campo Provincia del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_state_too_long = () => `Il campo Provincia del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_city_required = () => `Il campo Città del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_city_too_long = () => `Il campo Città del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_zip_required = () => `Il campo CAP del negozio è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_zip_too_long = () => `Il campo CAP del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_motivation_too_long = () => `Il campo Motivazione è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_email_invalid = () => `Il campo Email del negozio non è valido`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_email_too_long = () => `Il campo Email del negozio è troppo lungo`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_logo_field_name = () => `Il logo del negozio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_banner_field_name = () => `Il banner del negozio`




	
/**
 * @param {{ fieldName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const field_name_not_an_image = (params) => `${params.fieldName} non è un'immagine`




	
/**
 * @param {{ fieldName: NonNullable<unknown>, maxUploadSize: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const field_name_file_too_big = (params) => `${params.fieldName} è troppo grande massimo ${params.maxUploadSize} Mb`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_phone_required = () => `Il campo telefono è obbligatorio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const seller_phone_too_long = () => `Il campo telefono è troppo lungo`

