import { AuthService } from './auth';
import { AuthHandler } from './handlers/authHandler';

const authService = new AuthService();

export function initializeDOMEvents(): void {
	const authHandler = new AuthHandler(authService);

	//signIn events
	document.addEventListener('signIn', (event: Event) =>
		authHandler.loginFormSubmitHandler(event as CustomEvent),
	);

	document.addEventListener('signInWithGoogle', () => authHandler.loginWithGoogleHandler());

	document.addEventListener('signOut', () => authService.signOut());
}
